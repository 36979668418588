<template>
    <el-main>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="订单编号：">
                <el-input size="small" v-model="searchForm.order_no" placeholder="请输入订单编号"></el-input>
            </el-form-item>
            <el-form-item label="开票日期：">
                <el-date-picker @change="getTime" v-model="searchForm.start_time" size="small" type="daterange"
                    range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="开票类型：">
                <el-select v-model="searchForm.invoice_type" size="small" placeholder="请选择">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="普通发票" :value="1"></el-option>
                    <el-option label="增值税发票" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开票抬头：">
                <el-input size="small" v-model="searchForm.invoice_title" placeholder="请输入开票抬头"></el-input>
            </el-form-item>
            <el-form-item label="开票状态：">
                <el-select v-model="searchForm.status" size="small" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="已驳回" :value="-1"></el-option>
                    <el-option label="待审核" :value="1"></el-option>
                    <el-option label="已完成" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button type="primary" size="small" @click="serarch">搜索</el-button>
                <el-button size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="order_no" label="订单编号" align="center"></el-table-column>
            <el-table-column prop="invoice_title" label="开票抬头" align="center"></el-table-column>
            <el-table-column prop="invoice_type" label="开票类型" align="center">
                <template v-slot="{ row }">{{ row.invoice_type == 1 ? '增值税发票' : '普通发票' }}</template>
            </el-table-column>
            <el-table-column prop="amount" label="开票金额" align="center">
                <template v-slot="{ row }">￥{{ row.amount }}</template>
            </el-table-column>
            <el-table-column prop="create_time" label="开票时间" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="store_name" label="所属店铺名称" align="center"></el-table-column>
            <el-table-column prop="status" label="开票状态" align="center">
                <template v-slot="{ row }">
                    <span :style="{ color: row.status == 1 ? '#e6a23c' : row.status == 2 ? '#409eff' : '#f56c6c' }">{{
                        row.status == 1 ? '待审核' : row.status == 2 ? '已完成' : '已驳回' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="money" label="操作" align="center">
                <template v-slot="{ row }">
                    <div class="btn">
                        <el-link v-if="row.status == 2 && row.electronic_invoice" :underline="false" type="primary"
                            size="small" :href="row.electronic_invoice" target="_blank">下载</el-link>
                        <el-button @click="invoicing(row)" type="text">{{ row.status == 1 ? '开票' : '查看' }}</el-button>
                        <el-button @click="seeOrder(row.order_id)" type="text">查看订单</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog title="开票信息" :visible.sync="showInvoicing" width="850px">
            <el-radio-group style="margin-bottom: 20px" v-model="status" v-if="info.status == 1">
                <el-radio :label="2">同意开票</el-radio>
                <el-radio :label="-1">驳回开票</el-radio>
            </el-radio-group>
            <div class="invoicing-info">
                <div class="info-item">发票类型：{{ info.invoice_type ? '增值税发票' : '普通发票' }}</div>
                <div class="info-item">抬头类型：{{ info.title_type ? '企业' : '个人' }}</div>
                <div class="info-item">凭证类型：{{ info.voucher_type ? '纸质' : '电子' }}</div>
                <div class="info-item">申请时间：{{ getDateformat(info.create_time) }}</div>
                <div class="info-item">
                    订单编号：
                    <div @click="copyInfo($event, info.order_no)">{{ info.order_no }}</div>
                </div>
                <div class="info-item">
                    开票金额：
                    <span @click="copyInfo($event, info.amount)">{{ info.amount }}</span>
                </div>
                <div class="info-item">
                    抬头名称：
                    <span @click="copyInfo($event, info.invoice_title)">{{ info.invoice_title }}</span>
                </div>
                <template v-if="info.title_type == 1">
                    <div class="info-item">
                        单位税号：
                        <span @click="copyInfo($event, info.duty_paragraph)">{{ info.duty_paragraph }}</span>
                    </div>
                    <div class="info-item">
                        企业地址：
                        <span @click="copyInfo($event, info.company_address)">{{ info.company_address }}</span>
                    </div>
                    <div class="info-item">
                        企业电话：
                        <span @click="copyInfo($event, info.company_phone)">{{ info.company_phone }}</span>
                    </div>
                    <div class="info-item">
                        开户银行：
                        <span @click="copyInfo($event, info.bank)">{{ info.bank }}</span>
                    </div>
                    <div class="info-item">
                        银行账号：
                        <span @click="copyInfo($event, info.bank_account)">{{ info.bank_account }}</span>
                    </div>
                </template>
                <template v-if="info.voucher_type == 1">
                    <div class="info-item">
                        收货人：
                        <span @click="copyInfo($event, info.consignee_name)">{{ info.consignee_name }}</span>
                    </div>
                    <div class="info-item">
                        联系电话：
                        <span @click="copyInfo($event, info.consignee_phone)">{{ info.consignee_phone }}</span>
                    </div>
                    <div class="info-item">
                        详细地址：
                        <div @click="copyInfo($event, info.consignee_address)">{{ info.consignee_address }}</div>
                    </div>
                </template>
            </div>
            <div class="info-item" v-if="info.status == -1">驳回原因：{{ info.reason }}</div>
            <div class="invoicing-form" v-if="info.status == 1">
                <template v-if="status == 2">
                    <div class="item" v-if="info.voucher_type == 1">
                        <div>快递公司：</div>
                        <el-select v-model="express_id" filterable placeholder="请搜索">
                            <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name"
                                :value="item.id"></el-option>
                        </el-select>
                    </div>
                    <div class="item" v-if="info.voucher_type == 1">
                        <div>快递单号：</div>
                        <el-input v-model="express_number"></el-input>
                    </div>
                    <template v-if="info.voucher_type == 0">
                        <div class="item" style="align-items: center">
                            <div><span style="color:#f56c6c;">*</span>上传电子档发票：</div>
                            <el-input class="upload" placeholder="请上传PDF文件" v-model="electronic_invoice_pdf" disabled>
                                <el-button slot="append">
                                    上传文件
                                    <input type="file" accept=".pdf" ref="inputer" @change="upFile" />
                                </el-button>
                            </el-input>
                        </div>
                        <div class="item">
                            <div>上传发票图片：</div>
                            <ReadyUploadSource :showStyle="{
                                width: '180px',
                                height: '130px',
                            }" type="image" :path="electronic_invoice_picture"
                                @removeThis="() => (electronic_invoice_picture = '')"
                                @getSource="val => (electronic_invoice_picture = val.path)"></ReadyUploadSource>
                            <span style="color:#999;">（发票图片不上传，小程序端不显示发票预览图）</span>
                        </div>
                        <div class="item">
                            <div>发送到邮箱：</div>
                            <span>{{ info.email }}</span>
                        </div>
                    </template>
                </template>
                <div class="item" v-if="status == -1">
                    <div>驳回原因：</div>
                    <el-input v-model="reason"></el-input>
                </div>
            </div>
            <div class="success-info" v-if="info.status == 2">
                <div v-if="info.voucher_type == 1">快递公司：{{ info.express_name }}</div>
                <div v-if="info.voucher_type == 1">
                    快递单号：{{ info.express_number }}
                    <span type="text" @click="logisticsQuery">查看物流信息</span>
                </div>
                <div class="email-pdf">
                    PDF发票：
                    <el-button type="primary" size="mini">
                        <el-link style="color:#fff;" target="_blank" :underline="false"
                            :href="info.electronic_invoice_pdf">下载</el-link>
                    </el-button>
                </div>
                <div class="email" v-if="info.voucher_type == 0">
                    发票图片：
                    <el-image :src="info.electronic_invoice_picture" :preview-src-list="[info.electronic_invoice_picture]"
                        fit="scale-down"></el-image>
                </div>
                <div class="send" v-if="info.voucher_type == 0">
                    发送到邮箱：{{ info.email }}
                    <el-button type="primary" size="small" @click="sendEmail">发送</el-button>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" v-if="info.status == 1">
                <el-button @click="showInvoicing = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmInvoicing">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="物流信息" :visible.sync="showGistics" width="700px">
            <p style="font-size: 16px; font-weight: 500; margin-bottom: 15px">{{ logisticsInfo.company_name }}：{{
                logisticsInfo.nu || info.express_number }}</p>
            <el-timeline :reverse="reverse">
                <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
                    {{ item.context }}
                </el-timeline-item>
            </el-timeline>
        </el-dialog>
    </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import clipboard from '@/util/clipBoard.js';
import ReadyUploadSource from '@/components/readyUploadSource';
import { mapState } from 'vuex';
const industryName = '/Community'; //行业版本接口名称（用于更换重复接口）
export default {
    components: {
        Paging,
        ReadyUploadSource,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                order_no: '',
                invoice_type: '',
                invoice_title: '',
                status: '',
                start_time: '',
                end_time: '',
            },
            info: {},
            status: 2,
            showInvoicing: !1,
            showGistics: !1,
            logisticsInfo: [],
            list: [],
            express_id: '',
            express_number: '',
            electronic_invoice_picture: '',
            electronic_invoice_pdf: '',
            electronic_invoice_pdf_url: '',
            reason: '',
            total_number: 0,
        };
    },
    computed: {
        ...mapState({
            logisticsList: state => state.order.logisticsList, //物流公司列表
        }),
    },
    created () {
        this.getList();
        this.$store.dispatch('order/getLogistics', { rows: 10000 });
    },
    methods: {
        serarch () {
            this.searchForm.page = 1;
            this.getList();
        },
        cancelSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                order_no: '',
                invoice_type: '',
                invoice_title: '',
                status: '',
                start_time: '',
                end_time: '',
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        copyInfo (e, text) {
            clipboard(e, text);
        },
        seeOrder (id) {
            this.$router.push({
                path: '/repair/goods/orderinfo',
                query: {
                    id: id,
                },
            });
        },
        downloadInvoice (invoice) {
            let a = document.createElement('a');
            a.href = invoice;
            a.id = 'download';
            document.body.appendChild(a);
            a.click();
            let aDom = document.getElementById('download');
            document.body.removeChild(aDom);
        },
        upFile () {
            let files = this.$refs.inputer.files[0];
            let formData = new FormData();
            formData.append('files', files);
            this.$axios.uploadFile(this.$api.user.addFiles, formData).then(res => {
                if (res.code == 0) {
                    this.electronic_invoice_pdf_url = res.result.files_path;
                    this.electronic_invoice_pdf = res.result.path;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(industryName + this.$api.invoice.invoicesList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        invoicing (row) {
            this.$axios
                .post(industryName + this.$api.invoice.invoicesInfo, {
                    id: row.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showInvoicing = !0;
                        this.info = res.result;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        logisticsQuery () {
            this.$axios
                .post(industryName + this.$api.retail.newLogisticsQuery, {
                    express_id: this.info.express_id,
                    tracking_no: this.info.express_number,
                    phone: this.info.consignee_phone,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showGistics = !0;
                        this.logisticsInfo = res.result.info;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        sendEmail () {
            this.$axios.post(industryName + this.$api.invoice.sendEmail, { id: this.info.id }).then(res => {
                if (res.code == 0) {
                    this.$message.success("发送成功");
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        comfirmInvoicing () {
            try {
                let data = {
                    id: this.info.id,
                    status: this.status,
                };
                if (data.status == 2) {
                    if (this.info.voucher_type == 1) {
                        data.express_id = this.express_id;
                        data.express_number = this.express_number;
                        if (!data.express_id) throw '请选择快递公司';
                        if (!data.express_number) throw '请输入快递单号';
                    } else {
                        data.electronic_invoice_pdf_url = this.electronic_invoice_pdf_url;
                        data.electronic_invoice_picture = this.electronic_invoice_picture;
                        data.electronic_invoice_pdf = this.electronic_invoice_pdf;
                        if (!data.electronic_invoice_pdf_url) throw '请上传电子档发票';
                    }
                } else {
                    data.reason = this.reason;
                    if (!data.reason) throw '请输入驳回原因';
                }
                this.$axios.post(industryName + this.$api.invoice.invoicesEdit, data).then(res => {
                    if (res.code == 0) {
                        this.getList();
                        this.showInvoicing = !1;
                        this.$message.success('处理成功');
                        if (data.status == 2) return this.$axios.post(industryName + this.$api.invoice.sendEmail, { id: data.id });
                    } else {
                        this.$message.error(res.msg);
                    }
                }).then(res => {
                    if (res.code == 0) {
                        console.log(res);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            } catch (e) {
                this.$message.warning(e);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .invoicing-info {
        display: flex;
        flex-wrap: wrap;

        .info-item {
            width: 250px;
            margin-bottom: 15px;
            margin-right: 10px;
            display: flex;

            div {
                color: #409eff;
                cursor: pointer;
                flex: 1;
            }

            span {
                color: #409eff;
                cursor: pointer;
            }
        }
    }

    .invoicing-form {
        margin-top: 20px;

        .item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            div:nth-child(1) {
                text-align: right;
            }

            .el-input {
                width: 430px;
            }
        }
    }

    .success-info {
        div {
            display: flex;
            margin-bottom: 15px;

            span {
                color: #409eff;
                cursor: pointer;
                margin-left: 15px;
            }
        }

        .email .el-image {
            width: 400px;
            height: 200px;
        }

        .send {
            display: flex;
            align-items: center;

            .el-button {
                margin-left: 10px;
            }
        }

        .email-pdf {
            display: flex;
            align-items: center;
        }
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;

        .el-link {
            margin-right: 10px;
        }
    }

    .upload {
        .el-button {
            position: relative;

            input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
            }
        }
    }
}
</style>